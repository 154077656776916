import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'

const WebsitesPageTemplate = ({title, content, contentComponent}) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className='section edge--top is-light-primary'>
        <div className='container'>
          <PageContent className='content' content={content} />
        </div>
      </section>
    </div>
  )
}

WebsitesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default WebsitesPageTemplate
